import React, { useState } from 'react';
import Header from '../components/Header';
import ReactScan from '../components/Scanner';
import axios from "axios";
import "./Scanner.css";
import successSound from '../sound/success.mpeg';
import failureSound from '../sound/fail.mpeg';

const QRCodeReader = () => {
    const [scanResult, setScanResult] = useState(null);
    const [messages, setMessages] = useState([]);
    const [scanStatus, setScanStatus] = useState(''); 

    const handleOnScan = async (result) => {
        if (result) {
            const bookingId = result[0].rawValue;
            try {
                const response = await axios.get(bookingId, {
                    headers: { 'auth': document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1") }
                });
                setMessages([response.data]);
                setScanStatus('success');
                new Audio(successSound).play(); // Play success sound
            } catch (error) {
                const errorMsg = error.response ? error.response.data.message : 'An error occurred';
                setMessages([errorMsg]);
                setScanStatus('fail');
                new Audio(failureSound).play(); // Play failure sound
            }
            setScanResult(bookingId);
        }
    };

    const handleNewScan = () => {
        setScanResult(null);
        setMessages([]);
        setScanStatus('');
    };

    return (
        <div className="slot-container">
            <Header />
            <ReactScan onScan={handleOnScan} />
            <div className="scan-result">
                {scanResult && (
                    <pre>{JSON.stringify(scanResult, null, 2)}</pre>
                )}
                {messages.length > 0 && (
                    <div className="messages">
                        {messages.map((msg, index) => (
                            <p key={index}>{msg}</p>
                        ))}
                    </div>
                )}
            </div>
            <div className="scan-actions">
                <button onClick={handleNewScan} className="new-scan-button">
                    New Scan
                </button>
                <span className={`status-indicator ${scanStatus}`} />
            </div>
        </div>
    );
};

export default QRCodeReader;
