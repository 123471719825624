import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Modal, Button, Form, Container, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import './BankAccountModification.css';

const BankAccountModificationPage = () => {
  const [bankData, setBankData] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalData, setModalData] = useState({});

  // Fetch Bank Account Data
  const fetchBankData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/banking/get`, {
        headers: {
          'auth': document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1")
        }
      });
      console.log("response.data.bankAccount", response.data);
      setBankData(response.data);
    } catch (error) {
      console.error('Error fetching bank account data:', error);
  
      // Check if the error response exists and has a status of 404
      if (error.response && error.response.status === 404) {
        try {
          // Replace with the actual data you want to send in the POST request
          const newBankAccountData = {
            // Your bank account data goes here, e.g.:
            bankAccountName: 'Default Name',
            bankAccountNumber: '00000000',
            bankIFSC: 'N/A',
            bankName: 'Default Bank',
            bankBranch: 'Default Branch',
          };
  
          const addResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/banking/add`, newBankAccountData, {
            headers: {
              'auth': document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1")
            }
          });
  
          // Optionally handle the response from the add request
          console.log('Bank account added:', addResponse.data);
        } catch (addError) {
          console.error('Error adding bank account:', addError);
        }
      }
    }
  };
  

  useEffect(() => {
    fetchBankData();
  }, []);

  const handleShowEditModal = () => {
    setModalData({
      bankAccountName: bankData?.bankAccountName || '',
      bankAccountNumber: bankData?.bankAccountNumber || '',
      bankName: bankData?.bankName || '',
      ifscCode: bankData?.ifscCode || '',
    });
    setShowEditModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setModalData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/banking/update`, modalData, {
        headers: { 
          'auth': document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1") 
        }
      });
      setShowEditModal(false);
      toast.success('Bank Account Details Updated Successfully');
      fetchBankData();
    } catch (error) {
      toast.error('Error updating bank account data:', error);
    }
  };

  return (
    <>
      <Header />
      <Container className="bank-account-page mt-5">
        <Row className="justify-content-center">
          <Col md={6}>
            <Card className="shadow-lg mb-4">
              <Card.Header className="bg-primary text-white d-flex justify-content-between align-items-center">
                <h2>Bank Account Details</h2>
                <Button variant="light" onClick={handleShowEditModal}>Modify Details</Button>
              </Card.Header>
              <Card.Body>
                <Card.Title>Account Holder Name</Card.Title>
                <Card.Text>{bankData?.bankAccountName || 'Loading...'}</Card.Text>
                <Card.Title>Bank Account Number</Card.Title>
                <Card.Text>{bankData?.bankAccountNumber}</Card.Text>
                <Card.Title>Bank Name</Card.Title>
                <Card.Text>{bankData?.bankName}</Card.Text>
                <Card.Title>IFSC Code</Card.Title>
                <Card.Text>{bankData?.ifscCode}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Modal for Editing Bank Account Details */}
        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Bank Account Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="bankAccountName">
                <Form.Label>Account Holder Name</Form.Label>
                <Form.Control
                  type="text"
                  name="bankAccountName"
                  value={modalData.bankAccountName}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="bankAccountNumber">
                <Form.Label>Bank Account Number</Form.Label>
                <Form.Control
                  type="text"
                  name="bankAccountNumber"
                  value={modalData.bankAccountNumber}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="bankName">
                <Form.Label>Bank Name</Form.Label>
                <Form.Control
                  type="text"
                  name="bankName"
                  value={modalData.bankName}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="ifscCode">
                <Form.Label>IFSC Code</Form.Label>
                <Form.Control
                  type="text"
                  name="ifscCode"
                  value={modalData.ifscCode}
                  onChange={handleChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowEditModal(false)}>Close</Button>
            <Button variant="primary" onClick={handleSave}>Save Changes</Button>
          </Modal.Footer>
        </Modal>

        <ToastContainer />
      </Container>
    </>
  );
};

export default BankAccountModificationPage;
